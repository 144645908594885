import React from 'react'
import swiperimg from '../assets/swiperdemo.png'
import tetrisimg from '../assets/tetrisdemo.png'
import diceimg from '../assets/dicedemo.png'

const Cards = () => {
  
    const handleRedirect = (url:string) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    return (
    <div id='card' className='w-full py-[10rem] px-4 '>
        <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8'>

        <div className='w-full shadow-xl bg-gray-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
                <h2 className='text-4xl font-bold text-center'>Leet Translator</h2>
                <div className='flex flex-col justify-between text-center font-medium flex-grow'>
                    <p className='p-20 font-bold text-8xl'>1337</p>
                    <p className='py-2 border-t mx-4 mt-2'>Type like the true hackerman</p>
                </div>
                <button className='bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3 text-black' onClick={() => handleRedirect('https://translator.maggu.it')}>Explore</button>
            </div>
            
            <div className='w-full shadow-xl bg-gray-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
                <h2 className='text-4xl font-bold text-center'>Swiper</h2>
                <div className='flex flex-col justify-between text-center font-medium flex-grow'>
                    <img className='w-41 max-w-[200px] h-auto object-contain mx-auto bg-transparent mt-2 mb-2 rounded-lg' src={swiperimg} alt="/" />
                    <p className='py-2 border-t mx-4 mt-2'>A android dating app for cats, made in C#</p>
                </div>
                <button className='bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3 text-black' onClick={() => handleRedirect('https://github.com/maggobyte/Swiper')}>Explore</button>
            </div>

            <div className='w-full shadow-xl bg-gray-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
                <h2 className='text-4xl font-bold text-center'>Tetris</h2>
                <div className='flex text-center font-medium flex-col justify-between flex-grow'>
                    <img className='w-41 max-w-[200px] h-auto object-contain  mx-auto bg-transparent mt-2 mb-2 rounded-lg' src={tetrisimg} alt="/" />
                    <p className='py-2 border-t mx-4 mt-2'>Classic Tetris, made in c++</p>
                </div>
                <button className='bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3 text-black' onClick={() => handleRedirect('https://github.com/maggobyte/TetrisCpp')}>Explore</button>
            </div>

            <div className='w-full shadow-xl bg-gray-100 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
                <h2 className='text-4xl font-bold text-center'>Dice</h2>
                <div className='flex flex-col justify-between text-center font-medium flex-grow'>
                    <img className='w-41 max-w-[200px] h-auto object-contain mx-auto bg-transparent mt-2 mb-2 rounded-lg' src={diceimg} alt="/" />
                    <p className='py-2 border-t mx-4 mt-2'>Dice app for android made in C# using MAUI</p>
                </div>
                <button className='bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3 text-black' onClick={() => handleRedirect('https://github.com/maggobyte')}>Explore</button>
            </div>
            
            

            
        </div>
    </div>
  )
}

export default Cards