import React from 'react'
import ccccat from '../assets/ccccat.jpg'

const Sidekick = () => {
  
  const handleRedirect = (url:string) => {
    window.open(url, "_blank", "noopener,noreferrer");
};

  return (
    <div className='w-full bg-white py-16 px-4'>
        <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
            <div className='md:px-4'>
            <img className='w-[500px] mx-auto my-4 rounded-lg' src={ccccat} alt='/'/>
            </div>
            <div className='flex flex-col justify-center'>
                <p className='text-[#00df9a] uppercase font-bold'>The best cats ever?</p>
                <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Try Cursed Cat Central!</h1>
                <p>
                    The best cats ever.
                </p>
                <button className='bg-[#00df9a] w-[200px] rounded-md font-medium my-6 py-3 text-black' onClick={() => handleRedirect('https://runar.it')}>Try It</button>
            </div>
        </div>
    </div>
  )
}

export default Sidekick