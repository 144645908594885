import Navbar from './components/navbar';
import Hero from './components/hero';
import Sidekick from './components/sidekick';
import Cards from './components/cards';
import Footer from './components/footer';

function App() {
  
  
  return (
    <div>
      <Navbar />
      <Hero />
      <Sidekick />
      <Cards />
      <Footer />

    </div>
  );
}

export default App;
