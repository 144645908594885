import React from 'react'
import {FaGithubSquare} from 'react-icons/fa'

function Footer() {
  return (
    <div className='max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300'>
        <div>
            <h1 className='w-full text-3xl font-bold text-[#00df9a]'>MAGGO.</h1>
            <p className='py-4'>Was it worth making this website? Not really.</p>
            <div className='flex justify-between md:w-[75%] my-6'>
                <FaGithubSquare size={30} />
            </div>
        </div>
        
    </div>
  )
}

export default Footer